import { useI18next } from 'gatsby-plugin-react-i18next';
import { UserLang } from '@constants/common';
import React from 'react';
import Link from '@components/Link';

const ResourcesLink = ({ children, ...others }) => {
    const { language } = useI18next();
    return (
        <Link
            to={language === UserLang.German ? '/ressourcen/' : '/resources/'}
            {...others}
        >
            {' '}
            {children}
        </Link>
    );
};

export default ResourcesLink;
