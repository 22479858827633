import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import SignupForm from '@components/SignupForm/SignupForm';

const SignupFormStories: React.FC = () => {
    const { t } = useTranslation();
    return (
        <SignupForm
            title={t('stories.newsletter.form.title')}
            description={t('newsletter.signup.text')}
            cta={t('newsletter.signup.cta')}
            from="stories"
            hasBorder
        />
    );
};
export default SignupFormStories;
