import React, { useEffect, useRef } from 'react';
import './AvatarAudio.scss';

export default function AvatarAudio() {
    const myAudioTag = useRef(null);

    useEffect(() => {
        return () => {
            if (myAudioTag.current) {
                myAudioTag.current.removeEventListener('ended');
            }
        };
    }, []);

    return (
        <div className="AvatarAudio">
            {/* {gbContext.audioState ? "PLAY AUDIO" : "Default"} */}
            <div className="AvatarAudio--soundCircleWrapper">
                <div className="AvatarAudio--soundCircle"></div>
                <div className="AvatarAudio--soundCircleOut"></div>
            </div>
        </div>
    );
}
