import React, { useContext } from 'react';
import { FaPause, FaPlay } from 'react-icons/fa';
import { GlobalContext } from '@context/GlobalContext';
import './AvatarAudio.scss';

export default function AvatarAudioButton({ onchange, buttonState }) {
    const [gbContext] = useContext(GlobalContext);

    function handleToggle(e) {
        e.preventDefault();

        onchange();
    }

    return (
        <div
            className={
                gbContext.audioState && !buttonState
                    ? 'AvatarAudioButton is-off'
                    : 'AvatarAudioButton'
            }
            onClick={(e) => handleToggle(e)}
        >
            {buttonState ? <FaPause /> : <FaPlay />}
        </div>
    );
}
