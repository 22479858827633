import moment, { Moment } from 'moment';
import i18n from 'i18next';
import { UserLang } from '@constants/common';

export function renderDateTime(
    dateString: string | Moment,
    format: string
): string {
    const locale: any = i18n.language || UserLang.German;

    const date = moment(dateString);
    return date.locale(locale).format(format);
}
